import axios from 'axios'
import { SERVER_MXC_URL } from '../constants'

export interface WithdrawData {
  id: string
  txId: null | string
  coin: string
  network: string
  address: string
  amount: string
  transferType: 0 | 1
  status: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10
  transactionFee: string
  confirmNo: null | number
  applyTime: number
  remark: string
  memo: string
}

export interface CoinData {
  coin: string
  depositDesc: null | string
  depositEnable: boolean
  minConfirm: number
  name: string
  network: string
  withdrawEnable: boolean
  withdrawFee: string
  withdrawIntegerMultiple: null | string
  withdrawMax: string
  withdrawMin: string
  sameAddress: boolean
  contract: string
  withdrawTips: string
  depositTips: string
}

export interface CoinWrapData {
  coin: string
  name: string
  networkList: CoinData[]
}

export interface BalanceData {
  asset: string
  free: string
  locked: string
}

export interface BalanceWrapData {
  balances: BalanceData[]
}

// eslint-disable-next-line prettier/prettier
export interface CreateWithdrawPayload {
  coin: string
  network: string
  address: string
  amount: string
}

export default class OkxClient {
  private readonly axios

  constructor(private readonly key: string, private readonly secret: string) {
    this.axios = axios.create({
      baseURL: SERVER_MXC_URL,
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        'MXC-KEY': key,
        'MXC-SECRET': secret,
      },
    })
  }

  private getResponseData<T>(data: T): T | undefined | null {
    if (data) {
      return data
    }
    return null
  }

  async getWithdraws(): Promise<WithdrawData[]> {
    const resp = await this.axios.get('/getWithdraws')
    const data = this.getResponseData<WithdrawData[]>(resp.data)
    if (data) return data
    throw new Error('fetch error')
  }

  async getMarketCoins(): Promise<CoinData[]> {
    const resp = await this.axios.get('/getMarketCoins')
    const data = this.getResponseData<CoinWrapData[]>(resp.data)
    if (data) {
      return data.map((d) => d.networkList).reduce((a, b) => a.concat(b), [])
    }
    throw new Error('fetch error')
  }

  async getBalances(): Promise<BalanceData[]> {
    const resp = await this.axios.get('/getBalances')
    const data = this.getResponseData<BalanceWrapData>(resp.data)
    if (data) {
      return data.balances
    }
    throw new Error('fetch error')
  }

  async createWithdraw(
    payload: CreateWithdrawPayload
  ): Promise<{ id: string }> {
    try {
      const resp = await this.axios.post('/createWithdraw', payload)
      const data = this.getResponseData<{ id: string }>(resp.data)
      if (data) return data
    } catch (error: any) {
      const d = error.response?.data
      if (d) {
        if (d.error?.message?.code === 10212) {
          throw new Error('提现地址不在白名单内')
        } else if (d.error?.message?.msg) {
          throw new Error(d.error.message.msg)
        }
      }
    }
    throw new Error('fetch error')
  }
}
