import { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { createContainer } from 'unstated-next'
import {
  Exchange,
  AccountMode,
  DEFAULT_KEY,
  DEFAULT_KEY_SECRET,
  DEFAULT_KEY_PASSPHRASE,
} from '../constants'
import { Modal } from 'antd'

export enum HomeActiveMenu {
  home,
  glance,
  portfolio,
  reports,
  team,
  jobs,
}

interface useSystemProps {
  currentExchange: Exchange | undefined
  setCurrentChange: (currentExchange: Exchange | undefined) => void
  currentAccountMode: AccountMode | undefined
  setCurrentAccountMode: (currentAccountMode: AccountMode | undefined) => void
  currentKey: string | undefined
  setCurrentKey: (currentKey: string | undefined) => void
  currentKeySecret: string | undefined
  setCurrentKeySecret: (currentKeySecret: string | undefined) => void
  currentKeyPhrase: string | undefined
  setCurrentKeyPhrase: (currentKeyPhrase: string | undefined) => void
}

function useSystem(): useSystemProps {
  const [currentExchange, setCurrentChange] = useState<Exchange>()
  const [currentAccountMode, setCurrentAccountMode] = useState<AccountMode>()
  const [currentKey, setCurrentKey] = useState<string | undefined>(DEFAULT_KEY)
  const [currentKeySecret, setCurrentKeySecret] = useState<string | undefined>(
    DEFAULT_KEY_SECRET
  )
  const [currentKeyPhrase, setCurrentKeyPhrase] = useState<string | undefined>(
    DEFAULT_KEY_PASSPHRASE
  )

  const history = useHistory()

  const checkLogin = useCallback(() => {
    if (currentExchange && currentAccountMode) return true
    return false
  }, [currentExchange, currentAccountMode])

  useEffect(() => {
    if (!checkLogin()) {
      history.push('/')
    }
  }, [])

  useEffect(() => {
    history.listen(() => {
      Modal.destroyAll()
    })
  }, [history])

  return {
    currentExchange,
    setCurrentChange,
    currentAccountMode,
    setCurrentAccountMode,
    currentKey,
    setCurrentKey,
    currentKeySecret,
    setCurrentKeySecret,
    currentKeyPhrase,
    setCurrentKeyPhrase,
  }
}

const System = createContainer(useSystem)

export default System
