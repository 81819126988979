import axios from 'axios'
import { SERVER_OKX_URL } from '../constants'

export interface JsonResponseData<T = any> {
  code: string
  data?: T
}

export interface WithdrawData {
  chain: string
  fee: string
  feeCcy: string
  ccy: string
  clientId: string
  amt: string
  txId: string
  from: string
  areaCodeFrom: string
  to: string
  areaCodeTo: string
  state: string
  ts: string
  nonTradableAsset: boolean
  wdId: string
}

export interface CoinData {
  canDep: boolean
  canInternal: boolean
  canWd: boolean
  ccy: string
  chain: string
  depQuotaFixed: string
  depQuoteDailyLayer2: string
  logoLink: string
  mainNet: boolean
  maxFee: string
  maxWd: string
  minDep: string
  minDepArrivalConfirm: string
  minFee: string
  minWd: string
  minWdUnlockConfirm: string
  name: string
  needTag: boolean
  usedDepQuotaFixed: string
  usedWdQuota: string
  wdQuota: string
  wdTickSz: string
}

export interface BalanceData {
  availBal: string
  bal: string
  ccy: string
  frozenBal: string
}

// eslint-disable-next-line prettier/prettier
export type CreateWithdrawPayload = Pick<WithdrawData, 'ccy' | 'amt' | 'chain' | 'fee'> & {
  dest: '3' | '4'
  toAddr: string
}

export default class OkxClient {
  private readonly axios

  constructor(
    private readonly key: string,
    private readonly secret: string,
    private readonly keyPhrase: string
  ) {
    this.axios = axios.create({
      baseURL: SERVER_OKX_URL,
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        'OKX-KEY': key,
        'OKX-SECRET': secret,
        'OKX-PHRASE': keyPhrase,
      },
    })
  }

  private getResponseData<T>(data: JsonResponseData<T>): T | undefined | null {
    if (data.code === '0') {
      return data.data
    }
    return null
  }

  async getWithdraws(): Promise<WithdrawData[]> {
    const resp = await this.axios.get('/getWithdraws')
    const data = this.getResponseData<WithdrawData[]>(resp.data)
    if (data) return data
    throw new Error('fetch error')
  }

  async getMarketCoins(): Promise<CoinData[]> {
    const resp = await this.axios.get('/getMarketCoins')
    const data = this.getResponseData<CoinData[]>(resp.data)
    if (data) return data
    throw new Error('fetch error')
  }

  async getBalances(): Promise<BalanceData[]> {
    const resp = await this.axios.get('/getBalances')
    const data = this.getResponseData<BalanceData[]>(resp.data)
    if (data) return data
    throw new Error('fetch error')
  }

  async createWithdraw(payload: CreateWithdrawPayload): Promise<BalanceData[]> {
    const resp = await this.axios.post('/createWithdraw', payload)
    const data = this.getResponseData<BalanceData[]>(resp.data)
    if (data) return data
    const d = resp.data
    if (d.code === '58207') {
      throw new Error('提现地址不在白名单内')
    } else if (d.msg) {
      throw new Error(d.msg)
    }
    throw new Error('fetch error')
  }
}
