import React, { useEffect, useMemo, useState } from 'react'
import useInterval from '../../hooks/useInterval'
import { Tag, Progress, message } from 'antd'
import otp from '../../utils/otp'
import CopyToClipboard from 'react-copy-to-clipboard'

export interface OtpLabelProp {
  secret: string
  onChange?: (token: string) => void
}

const OtpLabel: React.FC<OtpLabelProp> = ({ secret, onChange }) => {
  const [time, setTime] = useState(30)
  const percent = useMemo(() => {
    return (time / 30) * 100
  }, [time])
  const [pass, setPass] = useState('')

  useInterval(() => {
    const now = new Date()
    const s = 30 - (now.getSeconds() % 30)
    setTime(s)
    setPass(otp(secret))
  }, 1000)

  useEffect(() => {
    if (onChange) {
      onChange(pass)
    }
  }, [pass])

  return (
    <CopyToClipboard text={pass} onCopy={() => message.success('复制成功')}>
      <Tag
        icon={
          <Progress
            percent={percent}
            width={15}
            type="circle"
            showInfo={false}
            strokeWidth={50}
            strokeLinecap="butt"
            style={{
              marginRight: 3,
            }}
          />
        }
      >
        <span style={{ verticalAlign: 'middle', fontWeight: 700 }}>{pass}</span>
      </Tag>
    </CopyToClipboard>
  )
}

export default OtpLabel
