import React, { useEffect, useMemo, useState } from 'react'
import FtxMainState from '../../state'
import {
  Button,
  Modal,
  Form,
  Input,
  message,
  InputNumber,
  Checkbox,
} from 'antd'
import {
  BalanceData,
  CoinData,
  CreateWithdrawPayload,
} from '../../../../../../apis/FtxClient'
import CoinSelect, {
  CoinSelectValue,
} from '../../../../../../components/CoinSelect'

interface ModalProps {
  visible: boolean
  onCancel: () => void
}
type CreateModalProps = ModalProps & {
  coins: CoinData[]
  commonCode: string
  commonPassword: string
}

type CreateValues = Omit<CreateWithdrawPayload, 'coin' | 'method'> & {
  coinMethod?: CoinSelectValue
}

const initialCreateValues: CreateValues = {
  coinMethod: undefined,
  size: 0,
  address: '',
}
const CreateModal: React.FC<CreateModalProps> = ({
  visible,
  onCancel,
  coins,
  commonCode,
  commonPassword,
}) => {
  const [loading, setLoading] = useState(false)
  const [autoVerify, setAutoVerify] = useState(true)
  const [balances, setBalances] = useState<BalanceData[]>([])
  const [selectCoinMethod, setSelectCoinMethod] = useState<CoinSelectValue>()
  const { ftxClient, updateWithdraws } = FtxMainState.useContainer()
  const [form] = Form.useForm()

  const selectCoinBalance = useMemo(() => {
    if (selectCoinMethod) {
      const coin = selectCoinMethod.coin
      const balance = balances.filter((b) => b.coin === coin)
      if (balance.length > 0) {
        return balance[0].availableForWithdrawal
      }
    }
    return 0
  }, [selectCoinMethod, balances])

  useEffect(() => {
    if (visible) {
      form.resetFields()
    }
  }, [visible])

  useEffect(() => {
    if (visible && ftxClient) {
      ftxClient
        .getBalances()
        .then(setBalances)
        .catch((e) => console.log(e))
    }
  }, [visible, ftxClient])

  useEffect(() => {
    if (autoVerify && visible) {
      if (commonCode) {
        form.setFields([
          {
            name: 'code',
            value: commonCode,
          },
        ])
      }
      if (commonPassword) {
        form.setFields([
          {
            name: 'password',
            value: commonPassword,
          },
        ])
      }
    }
  }, [commonCode, commonPassword, autoVerify, form, visible])

  const onFinish = (values: CreateValues): void => {
    if (!ftxClient) return
    if (!values.coinMethod) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.error('请选择币种')
      return
    }
    const coinMethod = values.coinMethod
    if (!coinMethod.method) {
      const coin = coins.find((c) => c.id === coinMethod.coin)
      if (!coin) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error('币种选择出错')
        return
      }
      if (!coin.methods || coin.methods.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error('请选择提币链')
        return
      }
    }
    if (values.size <= 0) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.error('提币数量必须大于0')
      return
    }
    setLoading(true)
    const payload: CreateWithdrawPayload = {
      coin: coinMethod.coin,
      method: coinMethod.method,
      size: values.size,
      address: values.address,
    }
    if (values.password) {
      payload.password = values.password
    }
    if (values.code) {
      payload.code = values.code
    }
    ftxClient
      .createWithdraw(payload)
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('创建提现成功')
        updateWithdraws()
        onCancel()
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false))
  }

  return (
    <Modal
      title="创建提现"
      visible={visible}
      onCancel={onCancel}
      footer={false}
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        form={form}
        name="basic"
        initialValues={{ ...initialCreateValues }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="币种"
          name="coinMethod"
          rules={[{ required: true, message: '请选择币种' }]}
        >
          <CoinSelect coins={coins} onChange={setSelectCoinMethod} />
        </Form.Item>
        <Form.Item label="可用余额：">
          <span>{selectCoinBalance}</span>
        </Form.Item>
        <Form.Item
          label="地址"
          name="address"
          rules={[{ required: true, message: '请填写地址' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="金额"
          name="size"
          rules={[{ required: true, message: '请填写金额' }]}
        >
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item label="提现密码（如有）" name="password">
          <Input.Password disabled={autoVerify} />
        </Form.Item>
        <Form.Item label="f2a（如有）" name="code">
          <Input disabled={autoVerify} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Checkbox
            checked={autoVerify}
            onChange={(e) => setAutoVerify(e.target.checked)}
          >
            通用f2a/密码自动填充
          </Checkbox>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            创建
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CreateModal
