import React, { useEffect, useMemo, useState } from 'react'
import MxcMainState from '../../state'
import {
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  message,
  // Input,
  // message,
  // InputNumber,
  // Checkbox,
} from 'antd'
import {
  BalanceData,
  CoinData,
  CreateWithdrawPayload,
} from '../../../../../../apis/MxcClient'

import CoinSelect, {
  CoinSelectValue,
} from '../../../../../../components/CoinSelectMxc'
// import { CoinSelectValue } from '../../../../../../components/CoinSelect'

interface ModalProps {
  visible: boolean
  onCancel: () => void
}
type CreateModalProps = ModalProps & {
  coins: CoinData[]
  commonCode: string
  commonPassword: string
}

// eslint-disable-next-line prettier/prettier
type CreateValues = Omit<CreateWithdrawPayload, 'coin' | 'network' | 'amount'> & {
  coinNetwork?: CoinSelectValue
  amount: number
}

const initialCreateValues: CreateValues = {
  coinNetwork: undefined,
  address: '',
  amount: 0,
}

const CreateModal: React.FC<CreateModalProps> = ({
  visible,
  onCancel,
  coins,
  // commonCode,
  // commonPassword,
}) => {
  const [loading, setLoading] = useState(false)
  const [balances, setBalances] = useState<BalanceData[]>([])
  const { mxcClient, updateWithdraws } = MxcMainState.useContainer()
  const [selectCoinMethod, setSelectCoinMethod] = useState<CoinSelectValue>()
  const [form] = Form.useForm()

  const selectCoinBalance = useMemo(() => {
    if (selectCoinMethod) {
      const coin = selectCoinMethod.coin
      const balance = balances.filter((b) => b.asset === coin)
      if (balance.length > 0) {
        return balance[0].free
      }
    }
    return 0
  }, [selectCoinMethod, balances])

  useEffect(() => {
    if (visible) {
      form.resetFields()
    }
  }, [visible])

  useEffect(() => {
    if (visible && mxcClient) {
      mxcClient
        .getBalances()
        .then(setBalances)
        .catch((e) => message.error(e))
    }
  }, [visible, mxcClient])

  const onFinish = (values: CreateValues): void => {
    if (!mxcClient) return
    if (!values.coinNetwork) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.error('请选择币种')
      return
    }
    const coinNetwork = values.coinNetwork
    if (!coinNetwork || !coinNetwork.coin || !coinNetwork.network) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.error('请选择提币链')
      return
    }
    if (values.amount <= 0) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.error('提币数量必须大于0')
      return
    }
    setLoading(true)
    const payload: CreateWithdrawPayload = {
      coin: coinNetwork.coin,
      network: coinNetwork.network,
      amount: values.amount.toString(),
      address: values.address,
    }
    mxcClient
      .createWithdraw(payload)
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('创建提现成功')
        updateWithdraws()
        onCancel()
      })
      .finally(() => setLoading(false))
      .catch((e) => {
        console.dir(e)
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(e.message)
      })
  }

  return (
    <Modal
      title="创建提现"
      visible={visible}
      onCancel={onCancel}
      footer={false}
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        form={form}
        name="basic"
        initialValues={{ ...initialCreateValues }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="币种"
          name="coinNetwork"
          rules={[{ required: true, message: '请选择币种' }]}
        >
          <CoinSelect coins={coins} onChange={setSelectCoinMethod} />
        </Form.Item>
        <Form.Item label="可用余额：">
          <span>{selectCoinBalance}</span>
        </Form.Item>
        <Form.Item
          label="地址"
          name="address"
          rules={[{ required: true, message: '请填写地址' }]}
          extra="必须是在白名单免认证地址列表中"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="金额"
          name="amount"
          rules={[{ required: true, message: '请填写金额' }]}
        >
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            创建
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CreateModal
