import { useState } from 'react'
import { createContainer } from 'unstated-next'
import { SubAccountData } from '../../../../apis/FtxClient'
import FtxMainState, { useFtxMainProps } from '../FtxMain/state'

interface useFtxSubProps {
  selectedSubAccounts: SubAccountData[]
  setSelectedSubAccounts: (selectedSubAccounts: SubAccountData[]) => void
}

function useFtxSub(): useFtxSubProps & useFtxMainProps {
  const ftxMainState = FtxMainState.useContainer()
  // eslint-disable-next-line prettier/prettier
  const [selectedSubAccounts, setSelectedSubAccounts] = useState<SubAccountData[]>([])

  return {
    selectedSubAccounts,
    setSelectedSubAccounts,
    ...ftxMainState,
  }
}

const FtxSubState = createContainer(useFtxSub)

export default FtxSubState
