import React, { useEffect, useMemo, useState } from 'react'
import { Select, Space } from 'antd'
import { CoinData } from '../../apis/FtxClient'

const Option = Select.Option

export interface CoinSelectValue {
  coin: string
  method?: string
}

export interface CoinSelectProps {
  value?: CoinSelectValue
  onChange?: (value?: CoinSelectValue) => void
  coins: CoinData[]
}

const CoinSelect: React.FC<CoinSelectProps> = ({ value, onChange, coins }) => {
  const [coinFilter, setCoinFilter] = useState<string>()
  const [methodFilter, setMethodFilter] = useState<string>()

  const seletedCoin = useMemo(() => {
    const coin = coins.find((coin) => coin.id === coinFilter)
    return coin
  }, [coinFilter, coins])

  useEffect(() => {
    if (!value) {
      setCoinFilter(undefined)
      setMethodFilter(undefined)
    } else {
      setCoinFilter(value.coin)
      setMethodFilter(value.method)
    }
  }, [value])

  const handleChangeCoin = (v: string): void => {
    if (!onChange) return
    onChange({
      coin: v,
    })
  }

  const handleChangeMethod = (v: string): void => {
    if (!value) return
    if (!onChange) return
    onChange({
      coin: value.coin,
      method: v,
    })
  }

  return (
    <Space>
      <Select
        value={coinFilter}
        onSelect={handleChangeCoin}
        style={{ width: 100 }}
        placeholder="币种"
        showSearch
        filterOption={(input, option) => {
          if (option?.value) {
            return option.value
              .toString()
              .toLowerCase()
              .startsWith(input.toLowerCase())
          }
          return false
        }}
      >
        {coins.map((coin) => (
          <Option value={coin.id} key={coin.id} disabled={!coin.canDeposit}>
            {coin.id}
          </Option>
        ))}
      </Select>
      {seletedCoin && (
        <Select
          value={methodFilter}
          onSelect={handleChangeMethod}
          style={{ width: 100 }}
          placeholder="充值方式"
        >
          {seletedCoin.methods.map((method) => (
            <Option value={method} key={method}>
              {method}
            </Option>
          ))}
        </Select>
      )}
    </Space>
  )
}

export default CoinSelect
