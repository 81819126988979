import { useCallback, useMemo, useState } from 'react'
import { createContainer } from 'unstated-next'
import System from '../../../../store/system'
import MxcClient, { WithdrawData } from '../../../../apis/MxcClient'

export interface useOkxMainProps {
  mxcClient: MxcClient | undefined
  withdraws: WithdrawData[]
  updateWithdraws: () => void
}

function useOkxMain(): useOkxMainProps {
  const { currentKey, currentKeySecret } = System.useContainer()
  const [withdraws, setWithdraws] = useState<WithdrawData[]>([])

  const mxcClient = useMemo(() => {
    if (currentKey && currentKeySecret) {
      return new MxcClient(currentKey, currentKeySecret)
    }
    return undefined
  }, [currentKey, currentKeySecret])

  const updateWithdraws = useCallback(() => {
    if (!mxcClient) return
    mxcClient
      .getWithdraws()
      .then(setWithdraws)
      .catch((e) => console.log(e))
  }, [mxcClient])

  return {
    mxcClient,
    withdraws,
    updateWithdraws,
  }
}

const OkxMainState = createContainer(useOkxMain)

export default OkxMainState
