import React, { useEffect, useState } from 'react'
import OkxMainState from '../../state'
import { Space, Table, Tag, Button, Input } from 'antd'
import { useTranslation } from 'react-i18next'
// import { DeleteFilled, EditFilled } from '@ant-design/icons'
import { CoinData, WithdrawData } from '../../../../../../apis/MxcClient'
// import showProgressModal from '../../../../../components/ProgressModal'
import OtpLabel from '../../../../../../components/OtpLabel'
import { formatDateTime } from '../../../../../../utils'
import CreateModal from './CreateModal'
import BulkCreateModal from './BulkCreateModal'

const Page: React.FC = () => {
  const [loading] = useState(false)
  const [createModalVisible, showCreateModal] = useState(false)
  const [coins, setCoins] = useState<CoinData[]>([])
  const [otpSecret, setOtpSecret] = useState('')
  const [selectOtpSecret, setSelectOtpSecret] = useState('')
  const [commonF2aCode, setCommonF2aCode] = useState('')
  const [commonPassword, setCommonPassword] = useState('')
  const [bulkCreateModalVisible, showBulkCreateModal] = useState(false)
  // const [selectedItem, setSelectedItem] = useState<SubAccountData>()
  // const [selectedItems, setSelectedItems] = useState<SubAccountData[]>([])
  const { updateWithdraws, withdraws, mxcClient } = OkxMainState.useContainer()
  const { t } = useTranslation('trans')

  useEffect(() => {
    updateWithdraws()
  }, [])

  useEffect(() => {
    if (!mxcClient) return
    mxcClient
      .getMarketCoins()
      .then(setCoins)
      .catch((e) => console.log(e))
  }, [mxcClient])

  const columns = [
    {
      title: '时间',
      dataIndex: 'applyTime',
      key: 'applyTime',
      render: (val: number) => formatDateTime(val),
    },
    {
      title: '币种',
      dataIndex: 'coin',
      key: 'coin',
      render: (_: any, row: WithdrawData) =>
        `${row.coin}${row.network ? ` - ${row.network}` : ''}`,
    },
    {
      title: '地址',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: '金额',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: '手续费',
      dataIndex: 'transactionFee',
      key: 'transactionFee',
    },
    {
      title: '状态',
      dataIndex: 'state',
      key: 'state',
      render: (val: string) => (
        <Tag color={t(`withdrawStatusColorMxc.${val}`)}>
          {t(`withdrawStatusMxc.${val}`)}
        </Tag>
      ),
    },
    // {
    //   title: '操作',
    //   key: 'action',
    //   dataIndex: 'action',
    //   render: (_: any, row: SubAccountData) => (
    //     <Space size="middle">
    //       <a
    //         onClick={() => {
    //           setSelectedItem(row)
    //           showModifyModal(true)
    //         }}
    //       >
    //         <EditFilled />
    //       </a>
    //       <Popconfirm
    //         title={`确认删除 ${row.nickname}?`}
    //         onConfirm={() => handleDelete(row)}
    //         okText="确认"
    //         cancelText="取消"
    //       >
    //         <a>
    //           <DeleteFilled />
    //         </a>
    //       </Popconfirm>
    //     </Space>
    //   ),
    // },
  ]

  return (
    <>
      <div className="withdraw-page">
        <div className="options">
          <Space style={{ opacity: 0, pointerEvents: 'none' }}>
            {selectOtpSecret ? (
              <Space>
                <OtpLabel
                  secret={selectOtpSecret}
                  onChange={setCommonF2aCode}
                />
              </Space>
            ) : (
              <Space>
                <Input
                  value={otpSecret}
                  onChange={(e) => setOtpSecret(e.target.value)}
                  placeholder="F2A Secret"
                />
                <Button
                  type="primary"
                  onClick={() => setSelectOtpSecret(otpSecret)}
                >
                  通用F2A
                </Button>
              </Space>
            )}
            <Input.Password
              value={commonPassword}
              onChange={(e) => setCommonPassword(e.target.value)}
              style={{ width: 150 }}
              placeholder="通用提现密码"
            />
          </Space>
          <Space>
            <Button type="primary" onClick={() => showCreateModal(true)}>
              创建提现
            </Button>
            <Button type="primary" onClick={() => showBulkCreateModal(true)}>
              创建批量提现
            </Button>
          </Space>
        </div>
        <div className="table">
          <Table
            className="withdraw-table"
            // rowSelection={{
            //   type: 'checkbox',
            //   onChange: (_, selectedRows: SubAccountData[]) => {
            //     setSelectedItems(selectedRows)
            //   },
            //   getCheckboxProps: (record: SubAccountData) => ({
            //     disabled: !record.deletable, // Column configuration not to be checked
            //   }),
            // }}
            loading={loading}
            columns={columns}
            dataSource={withdraws}
            rowKey="wdId"
            pagination={false}
          />
        </div>
      </div>
      <CreateModal
        visible={createModalVisible}
        onCancel={() => showCreateModal(false)}
        coins={coins}
        commonCode={commonF2aCode}
        commonPassword={commonPassword}
      />
      <BulkCreateModal
        visible={bulkCreateModalVisible}
        onCancel={() => showBulkCreateModal(false)}
        coins={coins}
        commonCode={commonF2aCode}
        commonPassword={commonPassword}
      />
    </>
  )
}

export default Page
