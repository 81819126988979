import { Tag, Tooltip } from 'antd'
import React from 'react'
import { formatEthHash } from '../../utils'

export interface HashTagProps {
  hash: string
  remain?: number
}

const HashTag: React.FC<HashTagProps> = ({ hash, remain = 10 }) => {
  return (
    <Tooltip title={hash}>
      <Tag>{formatEthHash(hash, remain)}</Tag>
    </Tooltip>
  )
}

export default HashTag
