import React, { useState, useEffect } from 'react'
import { Tabs, Button, Modal, Table } from 'antd'
// import { useTranslation } from 'react-i18next'
// import System from '../../store/system'
import MainState from '../FtxMain/state'
import SubState from './state'
import Balance from './pages/Balance'
import DepositAddress from './pages/DepositAddress'
import './style.scss'
import { SubAccountData } from '../../../../apis/FtxClient'

const { TabPane } = Tabs

const enum Page {
  balance = 'balance',
  depsit = 'depsit',
}

interface ModalProps {
  visible: boolean
  onCancel: () => void
}

const SelectSubAccountModal: React.FC<ModalProps> = ({ visible, onCancel }) => {
  const [tmpSelected, setTmpSelected] = useState<SubAccountData[]>([])
  const { subAccounts, selectedSubAccounts, setSelectedSubAccounts } =
    SubState.useContainer()

  useEffect(() => {
    if (visible) {
      setTmpSelected(selectedSubAccounts)
    }
  }, [visible])

  const columns = [
    {
      title: '账户昵称',
      dataIndex: 'nickname',
      key: 'nickname',
    },
  ]

  const handleOk = (): void => {
    setSelectedSubAccounts(tmpSelected)
    onCancel()
  }

  return (
    <Modal
      title="选择子账户"
      visible={visible}
      onCancel={onCancel}
      onOk={handleOk}
    >
      <Table
        rowSelection={{
          type: 'checkbox',
          onChange: (_, selectedRows: SubAccountData[]) => {
            setTmpSelected(selectedRows)
          },
          selectedRowKeys: tmpSelected.map((acc) => acc.nickname),
        }}
        columns={columns}
        dataSource={subAccounts}
        rowKey="nickname"
        pagination={false}
        size="small"
      />
    </Modal>
  )
}

const Mode: React.FC = ({ children }) => {
  const [selectModalVisible, showSelectModal] = useState(false)
  const [currentPage, setCurrentPage] = useState<Page>(Page.balance)
  const { updateSubAccounts, subAccounts, selectedSubAccounts } =
    SubState.useContainer()

  useEffect(() => {
    updateSubAccounts()
  }, [])

  return (
    <>
      <div className="ftxsub-mode">
        <Tabs
          type="card"
          onChange={(key) => setCurrentPage(key as Page)}
          activeKey={currentPage}
          tabBarExtraContent={{
            right: (
              <Button type="primary" onClick={() => showSelectModal(true)}>
                {`选择子账户 ${selectedSubAccounts.length}/${subAccounts.length}`}
              </Button>
            ),
          }}
        >
          <TabPane tab="子账户余额" key={Page.balance} />
          <TabPane tab="子账户充值地址" key={Page.depsit} />
        </Tabs>
        <div className="ftx-page">
          {currentPage === Page.balance && <Balance />}
          {currentPage === Page.depsit && <DepositAddress />}
        </div>
      </div>
      <SelectSubAccountModal
        visible={selectModalVisible}
        onCancel={() => showSelectModal(false)}
      />
    </>
  )
}

const ModeContainer: React.FC = ({ children }) => {
  return (
    <MainState.Provider>
      <SubState.Provider>
        <Mode />
      </SubState.Provider>
    </MainState.Provider>
  )
}

export default ModeContainer
