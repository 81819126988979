import React, { useEffect, useState } from 'react'
import FtxMainState from '../state'
import {
  Space,
  Table,
  Tag,
  Button,
  Modal,
  Form,
  Input,
  message,
  Popconfirm,
  InputNumber,
} from 'antd'
import { DeleteFilled, EditFilled } from '@ant-design/icons'
import {
  CreateSubAccountPayload,
  SubAccountData,
} from '../../../../../apis/FtxClient'
import showProgressModal from '../../../../../components/ProgressModal'

const MAX_COUNT = 50

interface ModalProps {
  visible: boolean
  onCancel: () => void
}

const initialCreateValues: CreateSubAccountPayload = {
  nickname: '',
}
const CreateModal: React.FC<ModalProps> = ({ visible, onCancel }) => {
  const [loading, setLoading] = useState(false)
  const { ftxClient, updateSubAccounts } = FtxMainState.useContainer()
  const [form] = Form.useForm()

  useEffect(() => {
    if (visible) {
      form.resetFields()
    }
  }, [visible])

  const onFinish = (values: any): void => {
    if (!ftxClient) return
    setLoading(true)
    ftxClient
      .createSubAccount(values)
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('创建成功')
        updateSubAccounts()
        onCancel()
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false))
  }

  return (
    <Modal
      title="创建子账户"
      visible={visible}
      onCancel={onCancel}
      footer={false}
    >
      <Form
        form={form}
        name="basic"
        initialValues={{ ...initialCreateValues }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="昵称"
          name="nickname"
          rules={[{ required: true, message: '请输入昵称' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            创建
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

const BulkCreateModal: React.FC<ModalProps> = ({ visible, onCancel }) => {
  const [loading, setLoading] = useState(false)
  const { ftxClient, updateSubAccounts, subAccounts } =
    FtxMainState.useContainer()
  const [form] = Form.useForm()

  useEffect(() => {
    if (visible) {
      form.resetFields()
    }
  }, [visible])

  const onFinish = async (values: any): Promise<void> => {
    if (!ftxClient) return
    setLoading(true)
    const progress = showProgressModal({
      title: '批量创建子账户进度',
      percent: 0,
    })

    const finallyCallback = (): void => {
      progress.destory()
      updateSubAccounts()
      setLoading(false)
    }
    for (let i = 0; i < values.count; i++) {
      const nickname = `${Date.now()}_${values.format as string}`
      await ftxClient
        .createSubAccount({ nickname })
        .then(() => {
          updateSubAccounts()
        })
        .catch((e) => {
          console.log(e)
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(`批量创建出错：${nickname}`)
          finallyCallback()
        })
      const percent = (i + 1) / values.count
      progress.update({
        percent,
      })
      if (percent >= 1) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('批量创建成功')
        finallyCallback()
        onCancel()
      }
    }
  }

  return (
    <Modal
      title="批量创建子账户"
      visible={visible}
      onCancel={onCancel}
      footer={false}
    >
      <Form
        form={form}
        name="basic"
        initialValues={{ format: '', count: 1 }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="昵称格式"
          name="format"
          rules={[{ required: true, message: '昵称格式' }]}
        >
          <Input addonBefore="{时间戳}_" placeholder="后缀" />
        </Form.Item>

        <Form.Item
          label="数量"
          name="count"
          rules={[{ required: true, message: '数量' }]}
        >
          <InputNumber min={1} max={MAX_COUNT - subAccounts.length} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            创建
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

const ModifyModal: React.FC<ModalProps & { data?: SubAccountData }> = ({
  visible,
  onCancel,
  data,
}) => {
  const [loading, setLoading] = useState(false)
  const { ftxClient, updateSubAccounts } = FtxMainState.useContainer()
  const [form] = Form.useForm()

  useEffect(() => {
    if (visible && data) {
      form.setFieldsValue(data)
    }
  }, [visible, data])

  const onFinish = (values: any): void => {
    if (!ftxClient || !data) return
    setLoading(true)
    ftxClient
      .updateSubAccountName({
        nickname: data.nickname,
        newNickname: values.nickname,
      })
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('创建成功')
        updateSubAccounts()
        onCancel()
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false))
  }

  return (
    <Modal
      title="修改子账户"
      visible={visible}
      onCancel={onCancel}
      footer={false}
    >
      <Form
        form={form}
        name="basic"
        initialValues={{ ...initialCreateValues }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="昵称"
          name="nickname"
          rules={[{ required: true, message: '请输入昵称' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            修改
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

const Page: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [createModalVisible, showCreateModal] = useState(false)
  const [modifyModalVisible, showModifyModal] = useState(false)
  const [bulkCreateModalVisible, showBulkCreateModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState<SubAccountData>()
  const [selectedItems, setSelectedItems] = useState<SubAccountData[]>([])
  const { updateSubAccounts, subAccounts, ftxClient } =
    FtxMainState.useContainer()

  useEffect(() => {
    updateSubAccounts()
  }, [])

  const handleDelete = (item: SubAccountData): void => {
    if (!ftxClient) return
    setLoading(true)
    ftxClient
      .deleteSubAccount({
        nickname: item.nickname,
      })
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('删除完成')
        updateSubAccounts()
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false))
  }

  const handleBulkDelete = async (): Promise<void> => {
    if (!ftxClient) return
    setLoading(true)
    const progress = showProgressModal({
      title: '批量删除子账户进度',
      percent: 0,
    })

    const finallyCallback = (): void => {
      progress.destory()
      updateSubAccounts()
      setLoading(false)
    }
    for (let i = 0; i < selectedItems.length; i++) {
      const nickname = selectedItems[i].nickname
      await ftxClient
        .deleteSubAccount({
          nickname,
        })
        .then(() => {
          updateSubAccounts()
        })
        .catch((e) => {
          console.log(e)
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(`批量删除出错：${nickname}`)
          finallyCallback()
        })
      const percent = (i + 1) / selectedItems.length
      progress.update({
        percent,
      })
      if (percent >= 1) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('批量删除成功')
        finallyCallback()
      }
    }
  }

  const columns = [
    {
      title: '账户昵称',
      dataIndex: 'nickname',
      key: 'nickname',
    },
    {
      title: '可删除',
      dataIndex: 'deletable',
      key: 'deletable',
      render: (val: boolean) =>
        val ? <Tag color="green">是</Tag> : <Tag color="red">否</Tag>,
    },
    {
      title: '可修改',
      dataIndex: 'deletable',
      key: 'deletable',
      render: (val: boolean) =>
        val ? <Tag color="green">是</Tag> : <Tag color="red">否</Tag>,
    },
    {
      title: '操作',
      key: 'action',
      dataIndex: 'action',
      render: (_: any, row: SubAccountData) => (
        <Space size="middle">
          <a
            onClick={() => {
              setSelectedItem(row)
              showModifyModal(true)
            }}
          >
            <EditFilled />
          </a>
          <Popconfirm
            title={`确认删除 ${row.nickname}?`}
            onConfirm={() => handleDelete(row)}
            okText="确认"
            cancelText="取消"
          >
            <a>
              <DeleteFilled />
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <>
      <div className="subaccount-page">
        <div className="options">
          <Space>
            {selectedItems.length > 0 && (
              // handleBulkDelete
              <Popconfirm
                title={`确认删除这 ${selectedItems.length} 个账户?`}
                onConfirm={async () => await handleBulkDelete()}
                okText="确认"
                cancelText="取消"
              >
                <Button type="primary">批量删除</Button>
              </Popconfirm>
            )}
          </Space>
          <Space>
            <span>{`子账户上限：${subAccounts.length} / ${MAX_COUNT}`}</span>
            <Button type="primary" onClick={() => showCreateModal(true)}>
              创建子账户
            </Button>
            <Button type="primary" onClick={() => showBulkCreateModal(true)}>
              批量创建子账户
            </Button>
          </Space>
        </div>
        <div className="table">
          <Table
            rowSelection={{
              type: 'checkbox',
              onChange: (_, selectedRows: SubAccountData[]) => {
                setSelectedItems(selectedRows)
              },
              getCheckboxProps: (record: SubAccountData) => ({
                disabled: !record.deletable, // Column configuration not to be checked
              }),
            }}
            loading={loading}
            columns={columns}
            dataSource={subAccounts}
            rowKey="nickname"
            pagination={false}
          />
        </div>
      </div>
      <CreateModal
        visible={createModalVisible}
        onCancel={() => showCreateModal(false)}
      />
      <ModifyModal
        visible={modifyModalVisible}
        onCancel={() => showModifyModal(false)}
        data={selectedItem}
      />
      <BulkCreateModal
        visible={bulkCreateModalVisible}
        onCancel={() => showBulkCreateModal(false)}
      />
    </>
  )
}

export default Page
