import React, { useCallback, useEffect, useMemo, useState } from 'react'
import FtxMainState from '../state'
import { Table, Space, Select, Button, message } from 'antd'
import { BalanceData, SubAccountData } from '../../../../../apis/FtxClient'
import showProgressModal from '../../../../../components/ProgressModal'

// const MAX_COUNT = 50

const Option = Select.Option

interface AccountBalance {
  nickname: string
  account: SubAccountData
  balances: BalanceData[]
}

const accountBalanceTableColumns = [
  {
    title: '币种',
    dataIndex: 'coin',
    key: 'coin',
    className: 'coin',
  },
  {
    title: '可用/总共',
    dataIndex: 'free',
    key: 'free',
    className: 'free',
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    render: (_: any, row: any) => `${row.free}/${row.total}`,
  },
  {
    title: '现货杠杆',
    dataIndex: 'spotBorrow',
    key: 'spotBorrow',
    className: 'spotBorrow',
  },
  {
    title: '可用余额(除借贷)',
    dataIndex: 'availableWithoutBorrow',
    key: 'availableWithoutBorrow',
    className: 'availableWithoutBorrow',
  },
  {
    title: '可提现余额',
    dataIndex: 'availableForWithdrawal',
    key: 'availableForWithdrawal',
    className: 'availableForWithdrawal',
  },
]

const AccountBalanceTable: React.FC<{ data: BalanceData[] }> = ({ data }) => {
  return (
    <Table
      className="balance-sub-table"
      columns={accountBalanceTableColumns}
      dataSource={data}
      rowKey="coin"
      pagination={false}
      size="small"
    />
  )
}

const Page: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [mainBalances, setMainBalances] = useState<BalanceData[]>([])
  const [accountBalances, setAccountBalances] = useState<AccountBalance[]>([])
  const [selectedItems, setSelectedItems] = useState<AccountBalance[]>([])
  const [coins, setCoins] = useState<string[]>([])
  const [coinFilter, setCoinFilter] = useState('*')
  const { updateSubAccounts, ftxClient, subAccounts } =
    FtxMainState.useContainer()

  const showAccountBalances = useMemo<AccountBalance[]>(() => {
    return accountBalances.map((ab) => {
      const ret: AccountBalance = {
        nickname: ab.nickname,
        account: ab.account,
        balances:
          coinFilter === '*'
            ? ab.balances
            : ab.balances.filter((balance) => balance.coin === coinFilter),
      }
      return ret
    })
  }, [accountBalances, coinFilter])

  const updateMainBalances = useCallback(() => {
    if (!ftxClient) return
    ftxClient
      .getBalances()
      .then((val) => setMainBalances(val.filter((v) => v.total > 0)))
      .catch((e) => console.log(e))
  }, [ftxClient, setMainBalances])

  const updateSubBalances = useCallback(() => {
    if (!ftxClient) return
    setSelectedItems([])
    if (subAccounts.length === 0) {
      setAccountBalances([])
    }
    const progress = showProgressModal({
      title: '查询进度',
      percent: 0,
    })
    ftxClient
      .bulkQuery(
        // eslint-disable-next-line @typescript-eslint/promise-function-async
        subAccounts.map((acc) =>
          ftxClient.subAccount(acc.nickname).getSubAccountBalances()
        ),
        (finish, total) =>
          progress.update({
            percent: finish / total,
          })
      )
      .then((balances) => {
        const abs = subAccounts.map<AccountBalance>((account, i) => ({
          nickname: account.nickname,
          account,
          balances: balances[i],
        }))
        setAccountBalances(abs)
        const map = new Set<string>()
        abs.forEach((ab) => {
          ab.balances.forEach((balance) => map.add(balance.coin))
        })
        setCoins(Array.from<string>(map))
        progress.destory()
      })
      .catch((e) => console.log(e))
  }, [ftxClient, setAccountBalances, subAccounts])

  useEffect(() => {
    updateSubAccounts()
    updateMainBalances()
  }, [ftxClient])

  useEffect(() => {
    updateSubBalances()
  }, [subAccounts, ftxClient])

  const columns = [
    {
      title: '子账户昵称',
      dataIndex: 'nickname',
      key: 'nickname',
      className: 'nickname',
    },
    {
      title: '余额',
      dataIndex: 'balances',
      key: 'balances',
      className: 'balances',
      render: (val: BalanceData[]) => <AccountBalanceTable data={val} />,
    },
  ]

  const handleCollect = async (): Promise<void> => {
    if (!ftxClient) return
    setLoading(true)
    const progress = showProgressModal({
      title: '批量创建子账户进度',
      percent: 0,
    })
    const finallyCallback = (): void => {
      progress.destory()
      updateSubBalances()
      updateMainBalances()
      setLoading(false)
    }
    for (let i = 0; i < selectedItems.length; i++) {
      const account = selectedItems[i]
      const balance = account.balances.find((b) => b.coin === coinFilter)
      if (balance && balance.availableForWithdrawal > 0) {
        await ftxClient
          .transferBetweenSubaccounts({
            coin: coinFilter,
            size: balance.availableForWithdrawal,
            source: account.nickname,
            destination: 'main',
          })
          .catch((e) => {
            console.log(e)
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.error(`归集出错：${account.nickname}`)
            finallyCallback()
          })
      }
      const percent = (i + 1) / selectedItems.length
      progress.update({
        percent,
      })
      if (percent >= 1) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('归集成功')
        finallyCallback()
      }
    }
  }

  const canCollect = coinFilter !== '*' && selectedItems.length > 0 && !loading
  return (
    <>
      <div className="collect-page">
        <h1>主账户余额</h1>
        <div className="table">
          <AccountBalanceTable data={mainBalances} />
        </div>
        <h1>子账户余额</h1>
        <div className="options">
          <Space>
            <span>选择币种：</span>
            <Select
              value={coinFilter}
              onSelect={setCoinFilter}
              style={{ width: 100 }}
            >
              <Option value="*">全部</Option>
              {coins.map((coin) => (
                <Option value={coin} key={coin}>
                  {coin}
                </Option>
              ))}
            </Select>
          </Space>
          <Space>
            <Button
              type="primary"
              disabled={!canCollect}
              onClick={handleCollect}
            >
              归集
            </Button>
          </Space>
        </div>
        <div className="table">
          <Table
            rowSelection={{
              type: 'checkbox',
              onChange: (_, selectedRows: AccountBalance[]) => {
                setSelectedItems(selectedRows)
              },
            }}
            className="balance-table"
            columns={columns}
            dataSource={showAccountBalances}
            rowKey="nickname"
            pagination={false}
          />
        </div>
      </div>
    </>
  )
}

export default Page
