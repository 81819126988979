import { useCallback, useMemo, useState } from 'react'
import { createContainer } from 'unstated-next'
import System from '../../../../store/system'
import OkxClient, { WithdrawData } from '../../../../apis/OkxClient'

export interface useOkxMainProps {
  okxClient: OkxClient | undefined
  withdraws: WithdrawData[]
  updateWithdraws: () => void
}

function useOkxMain(): useOkxMainProps {
  const { currentKey, currentKeySecret, currentKeyPhrase } =
    System.useContainer()
  const [withdraws, setWithdraws] = useState<WithdrawData[]>([])

  const okxClient = useMemo(() => {
    if (currentKey && currentKeySecret && currentKeyPhrase) {
      return new OkxClient(currentKey, currentKeySecret, currentKeyPhrase)
    }
    return undefined
  }, [currentKey, currentKeySecret, currentKeyPhrase])

  const updateWithdraws = useCallback(() => {
    if (!okxClient) return
    okxClient
      .getWithdraws()
      .then(setWithdraws)
      .catch((e) => console.log(e))
  }, [okxClient])

  return {
    okxClient,
    withdraws,
    updateWithdraws,
  }
}

const OkxMainState = createContainer(useOkxMain)

export default OkxMainState
