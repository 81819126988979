import React, { useEffect, useMemo, useState } from 'react'
import { Select, Space } from 'antd'
import { CoinData } from '../../apis/MxcClient'

const Option = Select.Option

export interface CoinSelectValue {
  coin: string
  network?: string
}

export interface CoinSelectProps {
  value?: CoinSelectValue
  onChange?: (value?: CoinSelectValue) => void
  coins: CoinData[]
}

const CoinSelect: React.FC<CoinSelectProps> = ({ value, onChange, coins }) => {
  const [coinFilter, setCoinFilter] = useState<string>()
  const [methodFilter, setMethodFilter] = useState<string>()
  const ccys = useMemo(() => {
    return Array.from(new Set(coins.map((c) => c.coin)))
  }, [coins])

  const seletedCoins = useMemo(() => {
    const coin = coins.filter((coin) => coin.coin === coinFilter)
    return coin
  }, [coinFilter, coins])

  useEffect(() => {
    if (!value) {
      setCoinFilter(undefined)
      setMethodFilter(undefined)
    } else {
      setCoinFilter(value.coin)
      setMethodFilter(value.network)
    }
  }, [value])

  const handleChangeCoin = (v: string): void => {
    if (!onChange) return
    onChange({
      coin: v,
    })
  }

  const handleChangeMethod = (v: string): void => {
    if (!value) return
    if (!onChange) return
    onChange({
      coin: value.coin,
      network: v,
    })
  }

  return (
    <Space>
      <Select
        value={coinFilter}
        onSelect={handleChangeCoin}
        style={{ width: 100 }}
        placeholder="币种"
        showSearch
        filterOption={(input, option) => {
          if (option?.value) {
            return option.value
              .toString()
              .toLowerCase()
              .startsWith(input.toLowerCase())
          }
          return false
        }}
      >
        {ccys.map((ccy) => (
          <Option value={ccy} key={ccy}>
            {ccy}
          </Option>
        ))}
      </Select>
      {seletedCoins.length > 0 && (
        <Select
          value={methodFilter}
          onSelect={handleChangeMethod}
          style={{ width: 150 }}
          placeholder="充值方式"
        >
          {seletedCoins.map((coin) => (
            <Option
              value={coin.network}
              key={coin.network}
              disabled={!coin.withdrawEnable}
            >
              {coin.network}
            </Option>
          ))}
        </Select>
      )}
    </Space>
  )
}

export default CoinSelect
