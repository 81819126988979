import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Steps, Space, Button, Radio, Input } from 'antd'
import System from '../../store/system'
import './style.scss'
import { AccountMode, AccountModeMap, Exchange } from '../../constants'
import { useHistory } from 'react-router-dom'

const { Step } = Steps

interface StepContentProps {
  onPrev?: () => void
  onNext?: () => void
  prevDisabled?: boolean
  nextDisabled?: boolean
  prevText?: string
  nextText?: string
}

const StepContent: React.FC<StepContentProps> = ({
  onPrev = () => {},
  onNext = () => {},
  prevDisabled = false,
  nextDisabled = false,
  prevText = '上一步',
  nextText = '下一步',
  children,
}) => {
  return (
    <>
      <div className="step-content">{children}</div>
      <div className="step-nav">
        <Space>
          <Button onClick={onPrev} disabled={prevDisabled}>
            {prevText}
          </Button>
          <Button onClick={onNext} disabled={nextDisabled}>
            {nextText}
          </Button>
        </Space>
      </div>
    </>
  )
}

const SelectExchange: React.FC<{ setStep: (step: number) => void }> = ({
  setStep,
}) => {
  const { t } = useTranslation('trans')
  const { currentExchange, setCurrentChange } = System.useContainer()

  const options = Object.keys(Exchange).map((key) => ({
    label: t(`exchange.${key}`),
    value: (Exchange as any)[key],
    disabled: key === 'ftx',
  }))

  return (
    <StepContent
      prevDisabled
      onNext={() => setStep(1)}
      nextDisabled={!currentExchange}
    >
      <Radio.Group
        options={options}
        value={currentExchange}
        onChange={(e) => setCurrentChange(e.target.value)}
        optionType="button"
        buttonStyle="solid"
      />
    </StepContent>
  )
}

const SelectMode: React.FC<{ setStep: (step: number) => void }> = ({
  setStep,
}) => {
  const { t } = useTranslation('trans')
  const { currentExchange, currentAccountMode, setCurrentAccountMode } =
    System.useContainer()

  if (!currentExchange) return <></>

  const modes = AccountModeMap[currentExchange]

  const options = modes.map((key) => ({
    label: t(`accountMode.${key}`),
    value: (AccountMode as any)[key],
  }))

  return (
    <StepContent
      onPrev={() => setStep(0)}
      onNext={() => setStep(2)}
      nextDisabled={!currentAccountMode}
    >
      <Radio.Group
        options={options}
        value={currentAccountMode}
        onChange={(e) => setCurrentAccountMode(e.target.value)}
        optionType="button"
        buttonStyle="solid"
      />
    </StepContent>
  )
}

const ImportKey: React.FC<{ setStep: (step: number) => void }> = ({
  setStep,
}) => {
  const {
    currentExchange,
    currentAccountMode,
    currentKey,
    currentKeySecret,
    setCurrentKey,
    setCurrentKeySecret,
    currentKeyPhrase,
    setCurrentKeyPhrase,
  } = System.useContainer()
  const [key, setKey] = useState(currentKey)
  const [secret, setSecret] = useState(currentKeySecret)
  const [keyPhrase, setKeyPhrase] = useState(currentKeyPhrase)
  const { t } = useTranslation('trans')
  const history = useHistory()

  if (!currentExchange || !currentAccountMode) return <></>

  const handleImport = (): void => {
    setCurrentKey(key)
    setCurrentKeySecret(secret)
    setCurrentKeyPhrase(keyPhrase)
    history.push(`/manage/${currentExchange}/${currentAccountMode}`)
  }

  return (
    <StepContent
      onPrev={() => setStep(1)}
      onNext={handleImport}
      nextDisabled={!key}
      nextText="导入"
    >
      <Space direction="vertical" style={{ display: 'flex' }}>
        <Input
          style={{ width: '100%' }}
          value={key}
          onChange={(e) => setKey(e.target.value)}
          placeholder={t(`accountModeKeyPlaceholder.${currentAccountMode}`)}
        />
        <Input
          style={{ width: '100%' }}
          value={secret}
          onChange={(e) => setSecret(e.target.value)}
          placeholder="对应 KEY 的 SECRET"
        />
        <Input
          style={{ width: '100%' }}
          value={currentKeyPhrase}
          onChange={(e) => setKeyPhrase(e.target.value)}
          placeholder="对应 KEY 的 Pass Phrase"
          disabled={currentAccountMode !== AccountMode.okxMain}
        />
      </Space>
    </StepContent>
  )
}

export const Home: React.FC = () => {
  const [step, setStep] = useState(0)

  return (
    <div id="home">
      <div>
        <div className="content">
          <Steps current={step} className="steps">
            <Step title="选择平台" />
            <Step title="选择模式" />
            <Step title="导入KEY" />
          </Steps>
          {step === 0 && <SelectExchange setStep={setStep} />}
          {step === 1 && <SelectMode setStep={setStep} />}
          {step === 2 && <ImportKey setStep={setStep} />}
        </div>
        <div className="version">version: {__VERSION__}</div>
      </div>
    </div>
  )
}
