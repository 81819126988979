/* eslint-disable @typescript-eslint/no-non-null-assertion */
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import hmacSHA256 from 'crypto-js/hmac-sha256'
import Hex from 'crypto-js/enc-hex'
import { message } from 'antd'
// import mockProjectImg from '../assets/img/icon_mock_project.svg'

export interface JsonResponseData<T = any> {
  success: boolean
  result?: T
}

interface QueryBox {
  query: Promise<any>
  index: number
}

type ProgressLogger = (finish: number, total: number) => void

export interface SubAccountData {
  deletable: boolean
  editable: boolean
  nickname: string
  // competition: boolean
  // special: boolean
}
export type CreateSubAccountPayload = Pick<SubAccountData, 'nickname'>
export interface UpdateSubAccountNamePayload {
  nickname: string
  newNickname: string
}
export type DeleteSubAccountPayload = Pick<SubAccountData, 'nickname'>

export enum WithdrawStatus {
  requested = 'requested',
  processing = 'processing',
  sent = 'sent',
  complete = 'complete',
  cancelled = 'cancelled',
}
export interface WithdrawData {
  coin: string
  // 内部转账可能不带
  address: string
  // 内部转账没有fee
  fee: number
  id: number
  size: number
  status: WithdrawStatus
  time: string
  method?: string
  txid?: string
  notes?: string
  tag?: string
}
// eslint-disable-next-line prettier/prettier
export type CreateWithdrawPayload = Pick<WithdrawData, 'coin' | 'size' | 'address' | 'tag' | 'method'> & {
  password?: string
  code?: string
}

export interface TransferBetweenSubaccountsPayload {
  coin: string
  size: number
  source: string
  destination: string
}

export interface TransferBetweenSubaccountsData {
  id: number
  coin: string
  size: number
  time: string
  notes: string
  status: string
}

export interface BalanceData {
  coin: string
  free: number
  total: number
  spotBorrow: number
  availableWithoutBorrow: number
  availableForWithdrawal: number
}

export interface CoinData {
  bep2Asset: any
  canConvert: boolean
  canDeposit: boolean
  canWithdraw: boolean
  collateral: boolean
  collateralWeight: number
  creditTo: any
  erc20Contract: string
  fiat: boolean
  hasTag: boolean
  id: string
  isToken: boolean
  methods: string[]
  name: string
  splMint: string
  trc20Contract: string
  usdFungible: boolean
}

export interface CoinDepositAddressData {
  address: string
  method: string
}

export default class FtxClient {
  private readonly privateAxios: AxiosInstance
  private readonly publicAxios: AxiosInstance

  constructor(
    private readonly key: string,
    private readonly secret: string,
    public subAccountName?: string
  ) {
    this.publicAxios = axios.create({
      baseURL: 'https://ftx.sacte.sekai.me/api/v1/forward/ftx',
      timeout: 300000,
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })

    this.privateAxios = axios.create({
      baseURL: 'https://ftx.sacte.sekai.me/api/v1/forward/ftx',
      timeout: 300000,
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        'FTX-KEY': this.key,
      },
    })
    this.privateAxios.interceptors.request.use(
      this.beforeRequest.bind(this),
      async (err) => await Promise.reject(err)
    )
    this.privateAxios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const response = error.response as AxiosResponse<any>
        if (response) {
          if (response.status === 401) {
            let msg = '此KEY没有权限：'
            if (response.data?.error?.message) {
              msg = msg + (response.data?.error?.message as string)
            }
            await message.error(msg)
          } else {
            if (response.data?.error?.message) {
              const msg = response.data?.error?.message
              if (typeof msg === 'string') {
                await message.error(msg)
              } else if (msg?.code && typeof msg.code === 'string') {
                await message.error(msg.code)
              } else {
                await message.error('未知错误')
              }
            }
          }
        }
        return await Promise.reject(error)
      }
    )
  }

  private beforeRequest(config: AxiosRequestConfig): AxiosRequestConfig {
    if (this.subAccountName) {
      config.headers['FTX-SUBACCOUNT'] = encodeURI(this.subAccountName)
    }

    const now = Date.now().toString()
    config.headers['FTX-TS'] = now

    const method = config.method!.toUpperCase()
    const { data, params, url } = config
    let sign = `${now}${method}/api${url!}`

    if (method === 'GET') {
      if (params) {
        const suffix = '?' + new URLSearchParams(params).toString()
        sign += suffix
      }
    }
    if (
      method === 'POST' ||
      method === 'PUT' ||
      method === 'PATCH' ||
      method === 'DELETE'
    ) {
      sign += JSON.stringify(data)
    }
    // console.log(sign)

    // switch (method) {
    //   case 'GET':
    //   case 'DELETE':
    //     sign += `/api${config.url!}?${new URLSearchParams(
    //       params
    //     ).toString()}`
    //     break
    //   case 'POST':
    //   case 'PUT':
    //   case 'PATCH':
    //     sign += `/api${config.url!}${JSON.stringify(data)}`
    // }
    config.headers['FTX-SIGN'] = hmacSHA256(sign, this.secret).toString(Hex)

    // console.log(
    //   hmacSHA256(
    //     '1588591856950POST/api/orders{"market": "BTC-PERP", "side": "buy", "price": 8500, "size": 1, "type": "limit", "reduceOnly": false, "ioc": false, "postOnly": false, "clientId": null}',
    //     'T4lPid48QtjNxjLUFOcUZghD7CUJ7sTVsfuvQZF2'
    //   ).toString(Hex)
    // )

    return config
  }

  // private async get(
  //   endpoint: string,
  //   params: any = {}
  // ): Promise<AxiosResponse<any>> {
  //   return await this.privateAxios.get(endpoint, { params })
  // }

  // private async delete(
  //   endpoint: string,
  //   params: any = {}
  // ): Promise<AxiosResponse<any>> {
  //   return await this.privateAxios.delete(endpoint, { params })
  // }

  // private async post(
  //   endpoint: string,
  //   data: any = {}
  // ): Promise<AxiosResponse<any>> {
  //   return await this.privateAxios.post(endpoint, data)
  // }

  private getResponseData<T>(data: JsonResponseData<T>): T | undefined | null {
    if (data.success) {
      return data.result
    }
    return null
  }

  /**
   * Public Method
   */

  async getMarketCoins(): Promise<CoinData[]> {
    const resp = await this.publicAxios.get('/wallet/coins')
    const data = this.getResponseData<CoinData[]>(resp.data)
    if (data) return data
    throw new Error('fetch error')
  }

  /**
   * MainAccount Method
   */

  async getSubAccounts(): Promise<any[]> {
    const resp = await this.privateAxios.get('/subaccounts')
    const data = this.getResponseData<SubAccountData[]>(resp.data)
    if (data) return data
    throw new Error('fetch error')
  }

  async createSubAccount(
    payload: CreateSubAccountPayload
  ): Promise<SubAccountData> {
    const resp = await this.privateAxios.post('/subaccounts', payload)
    const data = this.getResponseData<SubAccountData>(resp.data)
    if (data) return data
    throw new Error('fetch error')
  }

  async updateSubAccountName(
    payload: UpdateSubAccountNamePayload
  ): Promise<void> {
    const resp = await this.privateAxios.post(
      '/subaccounts/update_name',
      payload
    )
    const data = this.getResponseData<null>(resp.data)
    if (data === null) return
    throw new Error('fetch error')
  }

  async deleteSubAccount(payload: DeleteSubAccountPayload): Promise<void> {
    const resp = await this.privateAxios.delete('/subaccounts', {
      data: payload,
    })
    const data = this.getResponseData<null>(resp.data)
    if (data === null) return
    throw new Error('fetch error')
  }

  async getWithdraws(): Promise<WithdrawData[]> {
    const resp = await this.privateAxios.get('/wallet/withdrawals')
    const data = this.getResponseData<WithdrawData[]>(resp.data)
    if (data) {
      // 处理特殊情况
      return data.map((row) => ({
        ...row,
        address: row.address ?? '',
        fee: row.fee ?? 0,
      }))
    }
    throw new Error('fetch error')
  }

  async createWithdraw(payload: CreateWithdrawPayload): Promise<WithdrawData> {
    const resp = await this.privateAxios.post('/wallet/withdrawals', payload)
    const data = this.getResponseData<WithdrawData>(resp.data)
    if (data) return data
    throw new Error('fetch error')
  }

  async getBalances(): Promise<BalanceData[]> {
    const resp = await this.privateAxios.get('/wallet/balances')
    const data = this.getResponseData<BalanceData[]>(resp.data)
    if (data) return data
    throw new Error('fetch error')
  }

  async transferBetweenSubaccounts(
    payload: TransferBetweenSubaccountsPayload
  ): Promise<TransferBetweenSubaccountsData> {
    const resp = await this.privateAxios.post('/subaccounts/transfer', payload)
    const data = this.getResponseData<TransferBetweenSubaccountsData>(resp.data)
    if (data) return data
    throw new Error('fetch error')
  }

  /**
   * SubAccounts Method
   */

  subAccount(subAccountName: string): FtxClient {
    return new FtxClient(this.key, this.secret, subAccountName)
  }

  async bulkQuery(
    querys: Array<Promise<any>>,
    logger: ProgressLogger,
    split: number = 5
  ): Promise<any[]> {
    const queryGroups: QueryBox[][] = []
    querys.forEach((query, index) => {
      const i = Math.floor(index / split)
      const j = index % split
      if (!queryGroups[i]) {
        queryGroups[i] = []
      }
      queryGroups[i][j] = {
        query,
        index,
      }
    })
    const tmp: any = {}
    const checkResult = (): void => {
      logger(Object.keys(tmp).length, querys.length)
    }
    for (let i = 0; i < queryGroups.length; i++) {
      const group = queryGroups[i]
      await Promise.all(
        // eslint-disable-next-line @typescript-eslint/promise-function-async
        group.map((g) =>
          g.query.then((result: any) => {
            tmp[g.index] = result
            checkResult()
            return result
          })
        )
      )
    }
    const ret: any[] = []
    for (let i = 0; i < querys.length; i++) {
      ret[i] = tmp[i]
    }
    return ret
  }

  async getSubAccountBalances(
    subAccountName: string | undefined = this.subAccountName
  ): Promise<BalanceData[]> {
    if (!subAccountName) throw new Error('no subAccountName set')
    console.log(subAccountName)
    const resp = await this.privateAxios.get(
      `/subaccounts/${encodeURI(subAccountName)}/balances`
    )
    const data = this.getResponseData<BalanceData[]>(resp.data)
    if (data) return data
    throw new Error('fetch error')
  }

  async getSubAccountDepositAddress(
    coin: string,
    method: string,
    subAccountName: string | undefined = this.subAccountName
  ): Promise<CoinDepositAddressData> {
    if (!subAccountName) throw new Error('no subAccountName set')
    const resp = await this.privateAxios.get(
      `/wallet/deposit_address/${coin}`,
      { params: { method } }
    )
    const data = this.getResponseData<CoinDepositAddressData>(resp.data)
    if (data) return data
    throw new Error('fetch error')
  }

  async testReqest(): Promise<void> {
    const resp = await this.publicAxios.get('/markets/AAVE-0624')
    console.log(resp)
  }
}
