import React from 'react'
import { useTranslation } from 'react-i18next'
import System from '../../store/system'
import './style.scss'

const ManageLayout: React.FC = ({ children }) => {
  const { currentExchange, currentAccountMode } = System.useContainer()
  const { t } = useTranslation('trans')

  if (!currentExchange || !currentAccountMode) return <></>

  return (
    <div id="manage">
      <div className="header">
        <div className="container">
          <div className="nav">
            {`${t(`exchange.${currentExchange}`)} > ${t(
              `accountMode.${currentAccountMode}`
            )}`}
          </div>
        </div>
      </div>
      <div className="content">
        <div className="container">{children}</div>
      </div>
    </div>
  )
}

export default ManageLayout
