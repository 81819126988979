import React, { useEffect, useMemo, useState } from 'react'
import { Select, Space } from 'antd'
import { CoinData } from '../../apis/OkxClient'

const Option = Select.Option

export interface CoinSelectValue {
  ccy: string
  fee: string
  chain?: string
}

export interface CoinSelectProps {
  value?: CoinSelectValue
  onChange?: (value?: CoinSelectValue) => void
  coins: CoinData[]
}

const CoinSelect: React.FC<CoinSelectProps> = ({ value, onChange, coins }) => {
  const [coinFilter, setCoinFilter] = useState<string>()
  const [methodFilter, setMethodFilter] = useState<string>()
  const ccys = useMemo(() => {
    return Array.from(new Set(coins.map((c) => c.ccy)))
  }, [coins])

  const seletedCoins = useMemo(() => {
    const coin = coins.filter((coin) => coin.ccy === coinFilter)
    return coin
  }, [coinFilter, coins])

  useEffect(() => {
    if (!value) {
      setCoinFilter(undefined)
      setMethodFilter(undefined)
    } else {
      setCoinFilter(value.ccy)
      setMethodFilter(value.chain)
    }
  }, [value])

  const handleChangeCoin = (v: string): void => {
    if (!onChange) return
    onChange({
      ccy: v,
      fee: '0',
    })
  }

  const handleChangeMethod = (v: string): void => {
    if (!value) return
    if (!onChange) return
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const coin = coins.find((c) => c.ccy === value.ccy && c.chain === v)!
    onChange({
      ccy: value.ccy,
      chain: v,
      fee: coin.minFee,
    })
  }

  return (
    <Space>
      <Select
        value={coinFilter}
        onSelect={handleChangeCoin}
        style={{ width: 100 }}
        placeholder="币种"
        showSearch
        filterOption={(input, option) => {
          if (option?.value) {
            return option.value
              .toString()
              .toLowerCase()
              .startsWith(input.toLowerCase())
          }
          return false
        }}
      >
        {ccys.map((ccy) => (
          <Option value={ccy} key={ccy}>
            {ccy}
          </Option>
        ))}
      </Select>
      {seletedCoins.length > 0 && (
        <Select
          value={methodFilter}
          onSelect={handleChangeMethod}
          style={{ width: 150 }}
          placeholder="充值方式"
        >
          {seletedCoins.map((coin) => (
            <Option value={coin.chain} key={coin.chain} disabled={!coin.canWd}>
              {coin.chain.split('-')[1]}
            </Option>
          ))}
        </Select>
      )}
    </Space>
  )
}

export default CoinSelect
