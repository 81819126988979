import React, { useEffect, useMemo, useState } from 'react'
import MxcMainState from '../../state'
import {
  Button,
  Modal,
  Form,
  Input,
  // Popconfirm,
  InputNumber,
  // Checkbox,
  message,
  Alert,
} from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
// import { useTranslation } from 'react-i18next'
// import { DeleteFilled, EditFilled } from '@ant-design/icons'
import {
  BalanceData,
  CoinData,
  CreateWithdrawPayload,
} from '../../../../../../apis/MxcClient'
import CoinSelect, {
  CoinSelectValue,
} from '../../../../../../components/CoinSelectMxc'
import showProgressModal from '../../../../../../components/ProgressModal'
import { sleep } from '../../../../../../utils'

interface ModalProps {
  visible: boolean
  onCancel: () => void
}
type CreateModalProps = ModalProps & {
  coins: CoinData[]
  commonCode: string
  commonPassword: string
}

const bulkCreateAddressSizeImportPlaceholder = `0x314ab97b76e39d63c78d5c86c2daf8eaa306b182 3.141592
0x271bffabd0f79b8bd4d7a1c245b7ec5b576ea98a,2.7182
0x141ca95b6177615fb1417cf70e930e102bf8f584=1.41421
`

// eslint-disable-next-line prettier/prettier
const BulkCreateAddressSizeImportModal: React.FC<ModalProps & { onOk: (text: string) => void }> = ({ visible, onCancel, onOk }) => {
  const [value, setValue] = useState('')

  useEffect(() => {
    if (visible) {
      setValue('')
    }
  }, [visible])

  return (
    <Modal
      title="批量提现"
      visible={visible}
      onCancel={onCancel}
      onOk={() => onOk(value)}
    >
      <div>请按照下列格式导入：</div>
      <Input.TextArea
        autoSize={{ minRows: 4 }}
        placeholder={bulkCreateAddressSizeImportPlaceholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </Modal>
  )
}

interface BulkCreateAddressSizeInputValue {
  address: string
  amount: number
}

interface BulkCreateAddressSizeInputProps {
  value?: BulkCreateAddressSizeInputValue[]
  onChange?: (value: BulkCreateAddressSizeInputValue[]) => void
}

const rowReg = /^(?<address>[^ ,=]+)[ ,=](?<amount>[0-9]+\.?[0-9]*|\.[0-9]+)$/
function genAddressSizeGroup(text: string): BulkCreateAddressSizeInputValue[] {
  const rows = text
    .split('\n')
    .filter((r) => r)
    .map((r) => r.trim())
  const matches = rows.map((r) => r.match(rowReg))
  const errors: number[] = []
  const ret: BulkCreateAddressSizeInputValue[] = []
  matches.forEach((m, i) => {
    if (m) {
      ret.push({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        address: m.groups!.address,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        amount: parseFloat(m.groups!.amount),
      })
    } else {
      errors.push(i)
    }
  })
  if (errors.length > 0) {
    throw new Error(`第 ${errors.join(',')} 项格式错误`)
  }
  return ret
}

const BulkCreateAddressSizeInput: React.FC<BulkCreateAddressSizeInputProps> = ({
  value,
  onChange,
}) => {
  const [importVisible, showImport] = useState(false)

  const handleChange = (i: number, key: string, v: string | number): void => {
    if (!value || !onChange) return
    const row = value[i]
    if (!row) return
    ;(row as any)[key] = v
    onChange([...value])
  }

  const handleAdd = (): void => {
    if (!value || !onChange) return
    const row: BulkCreateAddressSizeInputValue = {
      address: '',
      amount: 0,
    }
    onChange([...value, row])
  }

  const handleDelete = (i: number): void => {
    if (!value || !onChange) return
    value.splice(i, 1)
    onChange([...value])
  }

  const handleImport = (text: string): void => {
    if (!value || !onChange) return
    try {
      const group = genAddressSizeGroup(text)
      onChange(group)
      showImport(false)
    } catch (e: any) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.error(e.message)
    }
  }

  return (
    <>
      <div className="bulk-create-address-size-input">
        <div className="bulk-create-address-size-input-opts">
          <Button onClick={() => showImport(true)}>批量导入</Button>
        </div>
        <div className="bulk-create-address-size-input-table">
          {(value ?? []).map((row, i) => (
            <div className="bulk-create-address-size-input-table-row" key={i}>
              <Input
                placeholder="地址"
                value={row.address}
                onChange={(e) => handleChange(i, 'address', e.target.value)}
              />
              <InputNumber
                min={0}
                placeholder="金额"
                value={row.amount}
                onChange={(v) => handleChange(i, 'amount', v)}
              />
              <Button
                icon={<DeleteOutlined />}
                onClick={() => handleDelete(i)}
              />
            </div>
          ))}
          <div className="bulk-create-address-size-input-table-row">
            <Button
              className="bulk-create-address-size-input-table-add"
              type="dashed"
              onClick={handleAdd}
            >
              添加
            </Button>
          </div>
        </div>
      </div>
      <BulkCreateAddressSizeImportModal
        visible={importVisible}
        onCancel={() => showImport(false)}
        onOk={handleImport}
      />
    </>
  )
}

// eslint-disable-next-line prettier/prettier
type BulkCreateValues = Omit<CreateWithdrawPayload, 'coin' | 'network' | 'amount' | 'address'> & {
  coinNetwork?: CoinSelectValue
  groups: BulkCreateAddressSizeInputValue[]
}
const initialBulkCreateValues: BulkCreateValues = {
  coinNetwork: undefined,
  groups: [],
}

const BulkCreateModal: React.FC<CreateModalProps> = ({
  visible,
  onCancel,
  coins,
}) => {
  const [loading, setLoading] = useState(false)
  const [balances, setBalances] = useState<BalanceData[]>([])
  const [selectCoinMethod, setSelectCoinMethod] = useState<CoinSelectValue>()
  const { mxcClient, updateWithdraws } = MxcMainState.useContainer()
  const [form] = Form.useForm()

  const selectCoinBalance = useMemo(() => {
    if (selectCoinMethod) {
      const coin = selectCoinMethod.coin
      const balance = balances.filter((b) => b.asset === coin)
      if (balance.length > 0) {
        return balance[0].free
      }
    }
    return 0
  }, [selectCoinMethod, balances])

  useEffect(() => {
    if (visible) {
      form.resetFields()
    }
  }, [visible])

  useEffect(() => {
    if (visible && mxcClient) {
      mxcClient
        .getBalances()
        .then(setBalances)
        .catch((e) => message.error(e))
    }
  }, [visible, mxcClient])

  const onFinish = async (values: BulkCreateValues): Promise<void> => {
    if (!mxcClient) return
    const coinNetwork = values.coinNetwork
    if (!coinNetwork || !coinNetwork.coin || !coinNetwork.network) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.error('请选择提币链')
      return
    }
    if (values.groups.length === 0) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.error('请输入至少一个地址/金额')
      return
    }
    const groupErrors = values.groups
      .map((group) => {
        if (!group.address) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error('有地址为空')
          return 1
        }
        if (!(group.amount > 0)) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(`${group.amount} 提币数量必须大于0`)
          return 1
        }
        return 0
      })
      .filter((e) => e)
    if (groupErrors.length > 0) return
    setLoading(true)
    const progress = showProgressModal({
      title: '批量提现进度',
      percent: 0,
    })

    const finallyCallback = (): void => {
      progress.destory()
      updateWithdraws()
      setLoading(false)
    }
    const payload: CreateWithdrawPayload = {
      coin: coinNetwork.coin,
      network: coinNetwork.network,
      amount: '0',
      address: '',
    }
    for (let i = 0; i < values.groups.length; i++) {
      const p: CreateWithdrawPayload = {
        ...payload,
        address: values.groups[i].address,
        amount: values.groups[i].amount.toString(),
      }
      progress.update({
        description: `${p.amount} -> ${p.address}`,
      })
      await mxcClient
        .createWithdraw(p)
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success(`提现第 ${i + 1} 项成功`)
          updateWithdraws()
        })
        .catch((e) => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises, @typescript-eslint/restrict-template-expressions
          message.error(`批量提现出错：${p.address}: ${e.message}`)
          finallyCallback()
        })
      const percent = (i + 1) / values.groups.length
      progress.update({
        percent,
      })
      if (percent >= 1) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.info('批量提现执行完毕，请检查记录')
        finallyCallback()
        onCancel()
      } else {
        progress.update({
          description: '连续提现防止问题：等待5秒',
        })
        await sleep(5_000)
      }
    }
  }

  return (
    <Modal
      title="批量提现"
      visible={visible}
      onCancel={onCancel}
      footer={false}
      width={750}
    >
      <Alert
        message="请注意！"
        description="若上次批量提现中途出错，请剔除已经发送成功的单个提现请求，否则会重新发起提现"
        type="warning"
        showIcon
        closable
        style={{
          marginBottom: 24,
        }}
      />
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        form={form}
        name="basic"
        initialValues={{ ...initialBulkCreateValues }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="币种"
          name="coinNetwork"
          rules={[{ required: true, message: '请选择币种' }]}
        >
          <CoinSelect coins={coins} onChange={setSelectCoinMethod} />
        </Form.Item>
        <Form.Item label="可用余额：">
          <span>{selectCoinBalance}</span>
        </Form.Item>
        <Form.Item
          label="地址/金额"
          name="groups"
          rules={[{ required: true, message: '请填写地址/金额' }]}
        >
          <BulkCreateAddressSizeInput />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            创建
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default BulkCreateModal
