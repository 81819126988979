import React, { useState } from 'react'
import { Tabs } from 'antd'
// import { useTranslation } from 'react-i18next'
// import System from '../../store/system'
import State from './state'
import Withdraw from './pages/Withdraw'
import './style.scss'

const { TabPane } = Tabs

const enum Page {
  withdraw = 'withdraw',
}

const Mode: React.FC = ({ children }) => {
  const [currentPage, setCurrentPage] = useState<Page>(Page.withdraw)
  // const { currentExchange, currentAccountMode } = System.useContainer()
  // const { t } = useTranslation('trans')

  return (
    <State.Provider>
      <div className="ftxmain-mode">
        <Tabs
          type="card"
          onChange={(key) => setCurrentPage(key as Page)}
          activeKey={currentPage}
        >
          <TabPane tab="提现" key={Page.withdraw} />
        </Tabs>
        <div className="ftx-page">
          {currentPage === Page.withdraw && <Withdraw />}
        </div>
      </div>
    </State.Provider>
  )
}

export default Mode
