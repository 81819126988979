import { useCallback, useMemo, useState } from 'react'
import { createContainer } from 'unstated-next'
import System from '../../../../store/system'
import FtxClient, {
  SubAccountData,
  WithdrawData,
} from '../../../../apis/FtxClient'

export interface useFtxMainProps {
  ftxClient: FtxClient | undefined
  subAccounts: SubAccountData[]
  updateSubAccounts: () => void
  withdraws: WithdrawData[]
  updateWithdraws: () => void
}

function useFtxMain(): useFtxMainProps {
  const { currentKey, currentKeySecret } = System.useContainer()
  const [subAccounts, setSubAccounts] = useState<SubAccountData[]>([])
  const [withdraws, setWithdraws] = useState<WithdrawData[]>([])

  const ftxClient = useMemo(() => {
    if (currentKey && currentKeySecret) {
      return new FtxClient(currentKey, currentKeySecret)
    }
    return undefined
  }, [currentKey, currentKeySecret])

  const updateSubAccounts = useCallback(() => {
    if (!ftxClient) return
    ftxClient
      .getSubAccounts()
      .then(setSubAccounts)
      .catch((e) => console.log(e))
  }, [ftxClient, setSubAccounts])

  const updateWithdraws = useCallback(() => {
    if (!ftxClient) return
    ftxClient
      .getWithdraws()
      .then(setWithdraws)
      .catch((e) => console.log(e))
  }, [ftxClient])

  return {
    ftxClient,
    subAccounts,
    updateSubAccounts,
    withdraws,
    updateWithdraws,
  }
}

const FtxMainState = createContainer(useFtxMain)

export default FtxMainState
