import React from 'react'
import { Modal, Progress } from 'antd'
import './style.scss'

interface ProgressModalOption {
  title: string
  percent: number
  description?: string
}

interface ProgressModalContentProps {
  percent: number
  description?: string
}

const ProgressModalContent: React.FC<ProgressModalContentProps> = ({
  percent,
  description,
}) => {
  return (
    <div className="progress-modal-content">
      <Progress type="circle" percent={Math.round(percent * 100)} />
      {description && (
        <div className="progress-modal-description">{description}</div>
      )}
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function showProgressModal(option: ProgressModalOption) {
  const { title, percent, description } = option
  const modal = Modal.info({
    className: 'progress-modal',
    title,
    content: (
      <ProgressModalContent percent={percent} description={description} />
    ),
    icon: null,
    okButtonProps: {
      style: {
        display: 'none',
      },
    },
  })

  let optionTmp: ProgressModalOption = { ...option }

  const destory = modal.destroy
  const update: (option: Partial<ProgressModalOption>) => void = (
    updateOption
  ) => {
    optionTmp = {
      ...optionTmp,
      ...updateOption,
    }
    const { title, percent, description } = optionTmp
    modal.update({
      title: title,
      content: (
        <ProgressModalContent percent={percent} description={description} />
      ),
    })
  }

  return {
    destory,
    update,
  }
}
