import React, { useCallback, useEffect, useMemo, useState } from 'react'
import FtxSubState from '../state'
import { Space, Button, Table, message } from 'antd'
import {
  CoinData,
  CoinDepositAddressData,
  SubAccountData,
} from '../../../../../apis/FtxClient'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import showProgressModal from '../../../../../components/ProgressModal'
import CoinSelect, {
  CoinSelectValue,
} from '../../../../../components/CoinSelect'

// const MAX_COUNT = 50

interface AccountAddress {
  nickname: string
  account: SubAccountData
  address: CoinDepositAddressData
}

const Page: React.FC = () => {
  const [addresses, setAddresses] = useState<AccountAddress[]>([])
  const [coins, setCoins] = useState<CoinData[]>([])
  const [coinMethodFilter, setCoinMethodFilter] = useState<CoinSelectValue>()
  const { ftxClient, selectedSubAccounts } = FtxSubState.useContainer()

  const coinFilter = useMemo(() => {
    if (coinMethodFilter) return coinMethodFilter.coin
    return undefined
  }, [coinMethodFilter])

  const methodFilter = useMemo(() => {
    if (coinMethodFilter) return coinMethodFilter.method
    return undefined
  }, [coinMethodFilter])

  useEffect(() => {
    if (!ftxClient) return
    ftxClient
      .getMarketCoins()
      .then(setCoins)
      .catch((e) => console.log(e))
  }, [ftxClient])

  const handleFetchData = useCallback(async () => {
    if (!ftxClient) return
    if (!coinFilter || !methodFilter) return
    const progress = showProgressModal({
      title: '查询进度',
      percent: 0,
    })
    await ftxClient
      .bulkQuery(
        // eslint-disable-next-line @typescript-eslint/promise-function-async
        selectedSubAccounts.map((acc) =>
          ftxClient
            .subAccount(acc.nickname)
            .getSubAccountDepositAddress(coinFilter, methodFilter)
        ),
        (finish, total) =>
          progress.update({
            percent: finish / total,
          })
      )
      .then((addresses) => {
        const aas = selectedSubAccounts.map<AccountAddress>((account, i) => ({
          nickname: account.nickname,
          account,
          address: addresses[i],
        }))
        setAddresses(aas)
        progress.destory()
      })
      .catch((e) => console.log(e))
  }, [ftxClient, coinFilter, methodFilter, selectedSubAccounts])

  useEffect(() => {
    handleFetchData().catch((e) => console.log(e))
  }, [selectedSubAccounts, ftxClient])

  const columns = [
    {
      title: '账户昵称',
      dataIndex: 'nickname',
      key: 'nickname',
      className: 'nickname',
    },
    {
      title: '充值地址',
      dataIndex: 'address',
      key: 'address',
      className: 'address',
      render: (val: CoinDepositAddressData) => (
        <CopyToClipboard
          text={val.address}
          onCopy={() => message.success('复制成功')}
        >
          <a>{val.address}</a>
        </CopyToClipboard>
      ),
    },
  ]

  return (
    <>
      <div className="balance-page">
        <div className="options">
          <Space>
            <CoinSelect
              value={coinMethodFilter}
              onChange={setCoinMethodFilter}
              coins={coins}
            />
          </Space>
          <Space>
            <Button onClick={handleFetchData} type="primary">
              查询充值地址
            </Button>
            {addresses.length > 0 && (
              <CopyToClipboard
                text={addresses.map((add) => add.address.address).join('\n')}
                onCopy={() => message.success('复制成功')}
              >
                <Button>复制所有地址</Button>
              </CopyToClipboard>
            )}
          </Space>
        </div>
        <div className="table">
          <Table
            className="deposit-table"
            columns={columns}
            dataSource={addresses}
            rowKey="nickname"
            pagination={false}
          />
        </div>
      </div>
    </>
  )
}

export default Page
