import React from 'react'
import ManageLayout from '../../components/ManageLayout'
import { AccountMode } from '../../constants'
import System from '../../store/system'
import { FtxMain, FtxSub, OkxMain, MxcMain } from './modes'
import './style.scss'

export const Manage: React.FC = () => {
  const { currentAccountMode } = System.useContainer()

  if (!currentAccountMode) return <></>

  return (
    <ManageLayout>
      {currentAccountMode === AccountMode.ftxMian && <FtxMain />}
      {currentAccountMode === AccountMode.ftxSub && <FtxSub />}
      {currentAccountMode === AccountMode.okxMain && <OkxMain />}
      {currentAccountMode === AccountMode.mxcMain && <MxcMain />}
    </ManageLayout>
  )
}
