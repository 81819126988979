import React, { useEffect, useMemo, useState } from 'react'
import FtxSubState from '../state'
import { Table, Space, Select } from 'antd'
import { BalanceData, SubAccountData } from '../../../../../apis/FtxClient'
import showProgressModal from '../../../../../components/ProgressModal'

// const MAX_COUNT = 50

const Option = Select.Option

interface AccountBalance {
  nickname: string
  account: SubAccountData
  balances: BalanceData[]
}

const accountBalanceTableColumns = [
  {
    title: '币种',
    dataIndex: 'coin',
    key: 'coin',
    className: 'coin',
  },
  {
    title: '可用/总共',
    dataIndex: 'free',
    key: 'free',
    className: 'free',
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    render: (_: any, row: any) => `${row.free}/${row.total}`,
  },
  {
    title: '现货杠杆',
    dataIndex: 'spotBorrow',
    key: 'spotBorrow',
    className: 'spotBorrow',
  },
  {
    title: '可用余额(除借贷)',
    dataIndex: 'availableWithoutBorrow',
    key: 'availableWithoutBorrow',
    className: 'availableWithoutBorrow',
  },
  {
    title: '可提现余额',
    dataIndex: 'availableForWithdrawal',
    key: 'availableForWithdrawal',
    className: 'availableForWithdrawal',
  },
]

const AccountBalanceTable: React.FC<{ data: BalanceData[] }> = ({ data }) => {
  return (
    <Table
      className="balance-sub-table"
      columns={accountBalanceTableColumns}
      dataSource={data}
      rowKey="coin"
      pagination={false}
      size="small"
    />
  )
}

const Page: React.FC = () => {
  const [accountBalances, setAccountBalances] = useState<AccountBalance[]>([])
  const [coins, setCoins] = useState<string[]>([])
  const [coinFilter, setCoinFilter] = useState('*')
  const { selectedSubAccounts, ftxClient } = FtxSubState.useContainer()

  const showAccountBalances = useMemo<AccountBalance[]>(() => {
    return accountBalances.map((ab) => {
      const ret: AccountBalance = {
        nickname: ab.nickname,
        account: ab.account,
        balances:
          coinFilter === '*'
            ? ab.balances
            : ab.balances.filter((balance) => balance.coin === coinFilter),
      }
      return ret
    })
  }, [accountBalances, coinFilter])

  useEffect(() => {
    async function fetchData(): Promise<void> {
      if (!ftxClient) return
      const progress = showProgressModal({
        title: '查询进度',
        percent: 0,
      })
      await ftxClient
        .bulkQuery(
          // eslint-disable-next-line @typescript-eslint/promise-function-async
          selectedSubAccounts.map((acc) =>
            ftxClient.subAccount(acc.nickname).getSubAccountBalances()
          ),
          (finish, total) =>
            progress.update({
              percent: finish / total,
            })
        )
        .then((balances) => {
          const abs = selectedSubAccounts.map<AccountBalance>((account, i) => ({
            nickname: account.nickname,
            account,
            balances: balances[i],
          }))
          setAccountBalances(abs)
          const map = new Set<string>()
          abs.forEach((ab) => {
            ab.balances.forEach((balance) => map.add(balance.coin))
          })
          setCoins(Array.from<string>(map))
          progress.destory()
        })
        .catch((e) => console.log(e))
    }
    fetchData().catch((e) => console.log(e))
  }, [selectedSubAccounts, ftxClient])

  const columns = [
    {
      title: '账户昵称',
      dataIndex: 'nickname',
      key: 'nickname',
      className: 'nickname',
    },
    {
      title: '余额',
      dataIndex: 'balances',
      key: 'balances',
      className: 'balances',
      render: (val: BalanceData[]) => <AccountBalanceTable data={val} />,
    },
  ]

  return (
    <>
      <div className="balance-page">
        <div className="options">
          <Space>
            <Select
              value={coinFilter}
              onSelect={setCoinFilter}
              style={{ width: 100 }}
            >
              <Option value="*">全部</Option>
              {coins.map((coin) => (
                <Option value={coin} key={coin}>
                  {coin}
                </Option>
              ))}
            </Select>
          </Space>
        </div>
        <div className="table">
          <Table
            className="balance-table"
            columns={columns}
            dataSource={showAccountBalances}
            rowKey="nickname"
            pagination={false}
          />
        </div>
      </div>
    </>
  )
}

export default Page
