export enum Exchange {
  ftx = 'ftx',
  okx = 'okx',
  mxc = 'MXC',
}

export enum AccountMode {
  ftxMian = 'ftxMian',
  ftxSub = 'ftxSub',
  okxMain = 'okxMain',
  mxcMain = 'mxcMain',
}

export const AccountModeMap = {
  [Exchange.ftx]: [AccountMode.ftxMian, AccountMode.ftxSub],
  [Exchange.okx]: [AccountMode.okxMain],
  [Exchange.mxc]: [AccountMode.mxcMain],
}
